<template>
  <v-dialog
    persistent
    @keydown.esc="close"
    v-model="active"
    :width="screenWidth + '%'"
    scrollable>
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-add-raw-material-purchase-order">
          <p class="title-add-raw-material-purchase-order">
            Actualizar merma del pesaje de: 
            <br> 
            <span
                style="color: var(--primary-color-text-title-detail); text-transform: uppercase; font-size: 18px">
                {{sRawMaterialInformation}}
            </span>
          </p>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container>
        <div class="content-current-amount">
          <div class="content-title">
            <p class="p-title">Cantidad actual de merma</p>
          </div>
          <div class="content-text">
            <p class="p-text">
              {{ formatMillon(OrderUnprocessedWeight) }}
              <span class="content-kg">Kg</span> 
            </p>
          </div>
        </div>
          <v-row>
            <v-col cols="12">
              <div class="content-text-field-account-name">
                <input-currency-global 
                    v-model="iWaste"
                    :flagModal="bFlag" 
                    labelGlobal="Nueva cantidad de merma"
                    classDynamic="global-text-field" 
                    :bOutlined="true" 
                    :bImportantData="false"
                    @changeMontoGlobal="changeMontoGlobal" 
                    :modelValue="iWaste" 
                    sSuffix="Kg" 
                    sPrefix=""
                />
              </div>
            </v-col>
            <v-col cols="12">
              <div class="content-question-confirm">
                <v-checkbox
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                  v-model="checkbox">
                  <template v-slot:label>
                    <div>
                      <p class="question-confirm">
                        Acepto la responsabilidad y 
                        deseo proseguir con la actualización 
                        de la merma de este pesaje: 
                      </p>
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-global mt-2 mb-6"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn
              @click="updateWaste"
              :disabled="!checkbox"
              :loading="bLoading"
              class="global-btn-primary">
              Confirmar
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    active: Boolean,
    oPurchaseOrder: Object,
  },
  data() {
    return {
      iWaste: 0.00,
      bFlag: true,
      checkbox: false,
      screenWidth: 0,
      bLoading: false,
      oItem: {},
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.close();
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    changeMontoGlobal(val) {
      this.value = parseFloat(val);
    },
    formatMillon(number) {
      let convert_number = Number(number).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    updateWaste() {
        this.bLoading = true
        const payload = {
            dUnprocessedWeight: parseFloat(this.iWaste),
        },
        config = {
            headers: {
                Authorization: "Bearer " + this.$store.state.sToken,
            },
          };
        DB.patch(`${URI}/api/sp/v1/orders/general/${this.$route.params.idOP}/items/${this.oPurchaseOrder.sOrderItemId}`,
          payload,
          config
        )
        .then((response) => {
            this.bLoading = false;
            this.$emit("refresh");
            this.close();
            this.Success(response.data.message);
            this.iWaste = 0
        })
        .catch((error) => {
            this.bLoading = false;
            this.Error(error.response.data);
        });
    },
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 70;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 50;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 40;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 30;
      }
    },
    close() {
      this.iWaste = 0.00
      this.checkbox = false
      this.bFlag = false
      this.$emit("close");
    },
  },
  computed: {
    sRawMaterialInformation () {
        return this.oPurchaseOrder == null? ' - ':this.oPurchaseOrder.oRawMaterial.sName 
    },
    OrderUnprocessedWeight () {
        return this.oPurchaseOrder == null ? null:this.oPurchaseOrder.dUnprocessedWeight
    }
  },
  watch: {
    active(value) {
      if (value) {
        this.bFlag = true
        this.iWaste = this.oPurchaseOrder.dUnprocessedWeight
      } else {
        this.iWaste = 0
      }
    },
  },
};
</script>
<style scoped>
.p-text {
  text-align: start;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-right: 5px;
}
.content-kg {
  margin-left: 10px;
  color: var(--primary-color-text-yellow);
}

.p-title {
  text-align: start;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-bottom: 0px;
}
.content-current-amount {
  /* display: flex; */
  width: 100%;
  margin-bottom: 15px;
}
.content-title-add-raw-material-purchase-order {
  width: 100%;
}
.content-description-add-raw-material-purchase-order {
  width: 100%;
}
.title-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
}
.description-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  font-family: "pop-Regular";
  font-size: 14px;
  text-align: center;
}

.content-question-add-raw-material-purchase-order {
  width: 100%;
  justify-self: center;
  display: flex;
  justify-content: center;
  padding: 10px 45px 10px 45px;
}

.question-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 12px;
  text-align: start;
  letter-spacing: 0px;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}
.content-btn-primary {
  width: 150px;
}
.content-btn-second {
  width: 150px;
  margin-right: 15px;
}
.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  width: 100%;
}
.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  width: 100%;
}
.table-raw-material {
  height: 300px;
  overflow: auto;
}
.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}
.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}
.content-question-confirm {
  width: 100%;
  justify-self: start;
  display: flex;
  justify-content: start;
  /* padding: 10px 45px 10px 45px; */
}

.question-confirm {
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 12px;
  text-align: start;
  letter-spacing: 0px;
}
/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .content-btn-primary {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>