<template>
  <v-dialog
    persistent
    @keydown.esc="close"
    v-model="active"
    :width="screenWidth + '%'"
    scrollable
  >
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-add-raw-material-purchase-order">
          <p class="title-add-raw-material-purchase-order">
            Modificar pesaje de:
            <br />
            <span class="txt-title-modify-material-op">
              {{ sRawMaterialInformation }}
            </span>
          </p>
        </div>
      </v-card-title>
      <v-card-text>
        <v-container v-if="oPurchaseOrder !== null">
          <v-row>
            <!-- #region Materia prima actual -->
            <v-col cols="12">
              <div class="content-current-amount">
                <div class="content-title">
                  <p class="p-title">Materia prima actual</p>
                </div>
                <div class="content-text">
                  <p class="p-text">
                    {{ sRawMaterialInformation }}
                  </p>
                </div>
              </div>
              <div class="content-text-field-account-name">
                <v-select
                  :loading="bLoadMaterials"
                  :disabled="bLoadMaterials"
                  v-model="sRawMaterialId"
                  :items="rawMaterials"
                  item-text="sName"
                  item-value="sRawMaterialId"
                  class="global-select"
                  color="var(--primary-color-border-input)"
                  background-color="var(--primary-color-menu)"
                  outlined
                >
                  <template slot="label">
                    <span> Nueva materia prima </span>
                  </template>
                </v-select>
              </div>
            </v-col>
            <!-- #endregion Materia prima actual -->

            <!-- #region Peso actual -->
            <v-col cols="12">
              <div class="content-current-amount">
                <div class="content-title">
                  <p class="p-title">Peso actual</p>
                </div>
                <div class="content-text">
                  <p class="p-text">
                    {{ formatMillon(iActualWeight) }}
                    <span class="content-kg">Kg</span>
                  </p>
                </div>
              </div>
              <div class="content-text-field-account-name">
                <!-- oOptionCurrencyKGGlobal -->
                <v-text-field-currency
                  v-model="iWeight"
                  outlined
                  label="Nuevo peso"
                  placeholder="0.00"
                  :bImportantData="false"
                  :options="oOptionCurrencyKGGlobal"
                />
              </div>
            </v-col>
            <!-- #endregion Peso actual -->

            <!-- #region Precio unitario actual MXN -->
            <v-col cols="12">
              <div class="content-current-amount">
                <div class="content-title">
                  <p class="p-title">Precio unitario actual</p>
                </div>
                <div class="content-text">
                  <p class="p-text">
                    {{ formatMillon(iCurrentPriceMXN) }}
                    <span class="content-kg">MXN</span>
                  </p>
                </div>
              </div>
              <div class="content-text-field-account-name">
                <v-text-field-currency
                  v-model="iPriceMXN"
                  outlined
                  label="Nuevo precio unitario"
                  placeholder="0.00"
                  :bImportantData="false"
                  :options="oOptionCurrencyMXNGlobal"
                />
              </div>
            </v-col>
            <!-- #endregion Precio unitario actual MXN-->

            <!-- #region Precio unitario actual USD -->
            <v-col cols="12">
              <div class="content-current-amount">
                <div class="content-title">
                  <p class="p-title">Precio unitario actual</p>
                </div>
                <div class="content-text">
                  <p class="p-text">
                    {{ formatMillon(iCurrentPriceUSD) }}
                    <span class="content-kg">USD</span>
                  </p>
                </div>
              </div>
              <div class="content-text-field-account-name">
                <v-text-field-currency
                  v-model="iPriceUSD"
                  outlined
                  label="Nuevo precio unitario"
                  placeholder="0.00"
                  :bImportantData="false"
                  :options="oOptionCurrencyUSDGlobal"
                />
              </div>
            </v-col>
            <!-- #endregion Precio unitario actual USD-->

            <v-col cols="12">
              <div class="content-question-confirm">
                <v-checkbox
                  class="global-checkbox"
                  color="var(--primary-color-text-yellow)"
                  v-model="checkbox"
                >
                  <template v-slot:label>
                    <div>
                      <p class="question-confirm">
                        Acepto la responsabilidad y deseo proseguir con la
                        actualización de la merma de este pesaje:
                      </p>
                    </div>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-global mt-2 mb-6"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn
              @click="updateMaterial"
              :disabled="!bValidateForm"
              :loading="bLoading"
              class="global-btn-primary"
            >
              Confirmar
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    active: Boolean,
    oPurchaseOrder: Object,
  },
  data() {
    return {
      bLoadMaterials: false,
      bFlagModal: false,
      iPriceMXN: null,
      iPriceUSD: null,
      iWeight: null,
      sRawMaterialId: "",
      checkbox: false,
      screenWidth: 0,
      bLoading: false,
      oItem: {},
      rawMaterials: [],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.close();
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    changeMontoGlobal(val) {
      this.value = parseFloat(val);
    },
    formatMillon(number) {
      let convert_number = Number(number).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getMaterials() {
      this.bLoadMaterials = true;
      DB.get(`${URI}/api/sp/v1/raw-materials`, {
        params: {
          iPageNumber: 1,
          iItemsPerPage: 100000,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.bLoadMaterials = false;
          this.rawMaterials = response.data.results;
          // this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoadMaterials = false;
          this.Error(error.response.data);
        });
    },
    updateMaterial() {
      this.bLoading = true;
      const payload = {
          dNetWeight: this.iWeight ? this.iWeight : 0,
          sRawMaterialId: this.sRawMaterialId,
          dMexicanPesoPrice: this.iPriceMXN ? this.iPriceMXN : 0,
          dUSDollarPrice: this.iPriceUSD ? this.iPriceUSD : 0,
        },
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        };
      DB.put(
        `${URI}/api/sp/v1/orders/general/${this.$route.params.idOP}/items/${this.oPurchaseOrder.sOrderItemId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.$emit("refresh");
          this.close();
          this.Success(response.data.message);
          // this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.Error(error.response.data);
        });
    },
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 70;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 50;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 40;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 30;
      }
    },
    close() {
      this.iPriceMXN = null;
      this.iPriceUSD = null;
      this.iWeight = null;
      this.sRawMaterialId = "";
      this.checkbox = false;
      this.bFlagModal = false;
      this.$emit("close");
    },
    updateAmounts() {
      // this.iWeight = this.oPurchaseOrder.dNetWeight;
      // this.iPriceMXN = this.oPurchaseOrder.dUnitPriceRawMaterial;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    sRawMaterialInformation() {
      return this.oPurchaseOrder == null
        ? " - "
        : this.oPurchaseOrder.oRawMaterial.sName;
    },
    OrderUnprocessedWeight() {
      return this.oPurchaseOrder == null
        ? null
        : this.oPurchaseOrder.dUnprocessedWeight;
    },
    iCurrentPrice() {
      return this.oPurchaseOrder == null
        ? null
        : this.oPurchaseOrder.dUnitPriceRawMaterial;
    },
    iCurrentPriceMXN() {
      return this.oPurchaseOrder == null
        ? null
        : this.oPurchaseOrder.dUnitPriceRawMaterialMXN;
    },
    iCurrentPriceUSD() {
      return this.oPurchaseOrder == null
        ? null
        : this.oPurchaseOrder.dUnitPriceRawMaterialUSD;
    },
    iActualWeight() {
      return this.oPurchaseOrder == null
        ? null
        : this.oPurchaseOrder.dNetWeight;
    },
    bValidateForm() {
      return this.sRawMaterialId !== null && this.checkbox;
    },
  },
  watch: {
    refreshTable: function () {
      this.getMaterials();
    },
    // workaround for currency field setting vmodel value to null
    // sRawMaterialId() {
    //   this.bValidateForm();
    // },
    // iPriceMXN() {
    //   this.bValidateForm();
    // },
    // iPriceUSD() {
    //   this.bValidateForm();
    // },
    // iWeight() {
    //   this.bValidateForm();
    // },
    active(value) {
      if (value) {
        this.bFlagModal = true;
        // this.updateAmounts();
        this.getMaterials();
        this.sRawMaterialId = this.oPurchaseOrder.oRawMaterial.sRawMaterialId;

        this.iWeight = this.iActualWeight;
        this.iPriceMXN = this.iCurrentPriceMXN;
        this.iPriceUSD = this.iCurrentPriceUSD;
      } else {
        this.iPriceMXN = null;
        this.iWeight = null;
        this.sRawMaterialId = "";
        this.bAssigned = false;
      }
    },
  },
};
</script>
<style scoped>
.txt-title-modify-material-op {
  color: var(--primary-color-text-title-detail);
  text-transform: uppercase;
  font-size: 18px;
}
.p-text {
  text-align: start;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-right: 5px;
}
.content-kg {
  margin-left: 10px;
  color: var(--primary-color-text-yellow);
}

.p-title {
  text-align: start;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-bottom: 0px;
}
.content-current-amount {
  /* display: flex; */
  width: 100%;
  margin-bottom: 15px;
}
.content-title-add-raw-material-purchase-order {
  width: 100%;
}
.content-description-add-raw-material-purchase-order {
  width: 100%;
}
.title-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
}
.description-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  font-family: "pop-Regular";
  font-size: 14px;
  text-align: center;
}

.content-question-add-raw-material-purchase-order {
  width: 100%;
  justify-self: center;
  display: flex;
  justify-content: center;
  padding: 10px 45px 10px 45px;
}

.question-add-raw-material-purchase-order {
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 12px;
  text-align: start;
  letter-spacing: 0px;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}
.content-btn-primary {
  width: 150px;
}
.content-btn-second {
  width: 150px;
  margin-right: 15px;
}
.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  width: 100%;
}
.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  width: 100%;
}
.table-raw-material {
  height: 300px;
  overflow: auto;
}
.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}
.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}
.content-question-confirm {
  width: 100%;
  justify-self: start;
  display: flex;
  justify-content: start;
  /* padding: 10px 45px 10px 45px; */
}

.question-confirm {
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 12px;
  text-align: start;
  letter-spacing: 0px;
}
/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .content-btn-primary {
    width: 100%;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
